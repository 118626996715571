<template>
    <div>
        <img src="@/assets/img/analyse-line.png" class="titleLine">
        <img src="@/assets/img/analyse-title3.png" class="titleBg">
        <div class="title">{{titleName}}<span> / {{titleEng}}</span></div>
    </div>
</template>

<script>
export default {
    naem: 'titleBG',
    props: {
        titleName: {
            type: String,
            default: ''
        },
        titleEng: {
            type: String,
            default: ''
        }
    },
    data () {
        return {

        }
    }

}
</script>

<style lang="scss" scoped>
    .title{
        font-family: "YouSheBiaoTiHei";
        font-size: 2.4vh;
        color: #D8F5FF;
        height: 4vh;
        line-height: 4.4vh;
        // background: red;
        // display: flex;
        // align-items: center;
        position: absolute;
        left: 1vh;
        top: 0;
        margin-left: 3vh;
        span{
            font-size: 1.85vh;
            color: #658698;
        }
    }
    .titleLine{
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .titleBg{
        height: 4vh;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .content{
        margin-top: 3vh;
    }
</style>