<template>
  <div class="analyse-item">
    <title-bg :titleName="titleName" :titleEng="titleEng"></title-bg>
    <div class="content">
      <div class="btn">
        <div
          class="btn-item"
          @click="btn_active = i + 1"
          :class="btn_active == i + 1 ? 'btn_active' : ''"
          v-for="(item, i) in btnList"
          :key="i"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="center" v-if="loading">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 2.25vh"
            spin
          />
        </a-spin>
      </div>
      <Echart
        id="centreLeft2Chart"
        ref="centreLeft2ChartRef"
        :options="options"
        height="90%"
        width="100%"
        v-else
      ></Echart>
    </div>
  </div>
</template>

<script>
import TitleBg from "./titleBG.vue";
import Echart from "@/common/echart";
export default {
  components: { TitleBg, Echart },
  data() {
    return {
      titleName: "平台统计",
      titleEng: "PLATFORM STATISTICS",
      btn_active: 1, // 选中的按钮类型
      options: {}, // 图表配置项
      loading: true, // 页面加载
      platformMonth: [], // 平台统计数据-月份
      platformValue: [], // 平台统计数据-数值
      btnList: [{ name: "事项量" }, { name: "浏览量" }, { name: "访问量" }],
      platformList: []
    };
  },
  created() {
    this.getPlatform();
  },
  watch: {
    btn_active: {
      handler() {
        this.getPlatform();
      },
    },
  },
  methods: {
    // 初始化
    init() {
      this.loading = true;
      this.platformMonth = [];
      this.platformValue = [];
    },
    // 获取平台统计数据
    getPlatform() {
      this.init();
      let params = {
        type: this.btn_active,
      };
      this.$api.analyse.platform(params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.loading = false;
          this.platformList = res.data;
          this.platformList.forEach((element) => {
            this.platformMonth.push(element.month);
            this.platformValue.push(element.value);
          });
          this.drawLine(this.platformList);
        }
        this.platformMonth = [];
        this.platformValue = [];
      });
    },
    // 绘制线型图
    drawLine() {
      this.options = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC",
            },
          },
        },
        legend: {
          data: ["数量"],
          show: false,
          textStyle: {
            color: "#B4B4B4",
          },
          top: "0%",
        },
        grid: {
          x: "10%",
          width: "85%",
          y: "20%",
        },
        xAxis: {
          data: this.platformMonth,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#B4B4B4",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            name: "单位:条",
            nameTextStyle: {
              pading: [0, 0, 0, -50],
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#3D5B7B",
                width: 1,
                type: "dashed",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "数量",
            type: "line",
            smooth: true,
            symbol: "none",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#33DCFE",
              },
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0.5, color: "rgba(0, 199, 254, 0.5)" },
                { offset: 1, color: "rgba(0, 200, 255, 0.25)" },
              ]),
            },
            data: this.platformValue,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.analyse-item {
  position: relative;
  background: url("../../../assets/img/analyse-item2.png") no-repeat;
  background-size: 100% 100%;
  height: 30vh;
  margin-right: 3vh;
  margin-bottom: 3vh;
  padding-top: 5vh;
  .content {
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 2vh;
    padding-top: 1.5vh;
    .btn {
      display: inline-flex;
      justify-content: space-around;
      width: 60%;
      height: 3.5vh;
      color: #ffffff;
      font-size: 1.66vh;
      margin: 0 auto;
      cursor: pointer;
      .btn-item {
        width: 8vh;
        // padding: 0 1.5vh;
        text-align: center;
        line-height: 3.5vh;
      }
      .btn_active {
        background: linear-gradient(
          180deg,
          rgba(97, 173, 255, 0) 0%,
          rgba(8, 185, 244, 0.64) 97.22%
        );
        border: 2px solid #33ccff;
        border-radius: 8px;
      }
    }
    .center {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
}
</style>