<template>
  <div class="head-bd">
    <div class="title">鳌江镇综合行政执法助手</div>
    <div class="flex-item">
      <div class="date">{{date}}</div>
      <div class="week">{{week}}</div>
      <div class="time">{{time}}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      date: moment().format('YYYY-MM-DD'),
      week: '',
      time: moment().format('HH:mm:ss')
    };
  },
  components: {
    
  },
  props: {
    
  },
  watch: {

  },
  mounted() {
    var time = setInterval(()=>{
      this.time = moment().format('HH:mm:ss');
    },1000)
    this.week = this.getWeek();
  },
  methods: {
    // 获取周几
    getWeek () { // 参数时间戳
      let week = moment().day()
      switch (week) {
        case 1:
          return '周一'
        case 2:
          return '周二'
        case 3:
          return '周三'
        case 4:
          return '周四'
        case 5:
          return '周五'
        case 6:
          return '周六'
        case 0:
          return '周日'
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.head-bd {
  width: 100%;
  height: 10.23vh;
  background: pink;
  padding: 0 1.66vw;
  display: flex;
  align-items: center;
  justify-content: space-between;   
  background: url('../../assets/img/header.png') no-repeat;
  background-size: 100%;
  position: relative;
  left: 0;
  top: 0;
  .title {
    font-family: 'YouSheBiaoTiHei';
    font-size: 3.72vh;
    letter-spacing: 0.372vh;
    line-height: 5.58vh;
    background: linear-gradient(180deg, #FFFFFF 28.88%, #56ADFF 78.33%, #56A8FF 78.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 8px 12px rgba(1, 13, 23, 0.3);
  }
  .flex-item {
    display: flex;
    align-items: flex-end;
    color: #fff;
    div {
      margin-left: 0.5vh;
    }
    .time {
      font-family: 'PangMenZhengDao';
      font-size: 2.23vh;
      line-height: 2.23vh;
      margin-left: 1.5vh;
    }
  }
}
</style>