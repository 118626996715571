<template>
  <div class="analyse-item">
    <title-bg :titleName="titleName" :titleEng='titleEng'></title-bg>
    <div class="content">
      <div class="center" v-if="loading">
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 2.25vh" spin />
        </a-spin>
      </div>
      <Echart
        id="rightTopChart"
        ref="rightTopChartRef"
        :options="options"
        :enterId="enterId"
        :leaveId="leaveId"
        :clickId="clickId"
        height="90%"
        width="100%"
        v-else
      ></Echart>
      <div class="legend" v-if="!loading">
        <div v-for="(item,i) in projectPercentList" :key="i" @click="click(i)" @mouseenter="mouseenter(i)" @mouseleave="mouseleave(i)">
          <div class="box" :style="{background: item.color}"></div>
          <span>{{item.name}}</span>
        </div>
        <!-- <img src="@/assets/img/pieCircle.png" class="pieCircle"> -->
      </div>
    </div>
  </div>
</template>

<script>
import TitleBg from './titleBG.vue'
import Echart from '@/common/echart';
export default {
  components: { TitleBg, Echart },
  data () {
    return {
      titleName: '条例分区占比',
      titleEng: 'REGULATIONS',
      loading: true, // 页面加载
      options: {}, // 图表配置项
      projectPercentList: [], // 饼图数据
      clickId: -1,
      enterId: -1,
      leaveId: -1,
      colorList: [
        '#800000',
        '#B22222',
        '#FF0000',
        '#DC143C',
        '#F76464',
        '#EF986C',
        '#E9967A',
        '#EFC36C',
        '#FFD700',
        '#EFEF6C',
        '#C3EF6C',
        '#98EF6C',
        '#5CD65C',
        '#6CEF98',
        '#86F9D2',
        '#6CEFEF',
        '#5CC9FF',
        '#ADD8E6',
        '#3377FF',
        '#7A7AF0',
        '#9364F1',
        '#9a60b4',
        '#FF00FF',
        '#9932CC',
        '#8B008B',
      ]
    }
  },
  created () {
    this.getprojectPercent()
  },
  mounted () {
  },
  methods: {
    // 点击图例
    click (i) {
      this.clickId = i
    },
    // 鼠标移入图例
    mouseenter (i) {
      this.enterId = i
      this.leaveId = -1
    },
    // 鼠标移出图例
    mouseleave (i) {
      this.leaveId = i
      this.enterId = -1
    },
    // 获取条例区分占比数据
    getprojectPercent () {
      this.$api.analyse.projectPercent().then(res => {
        console.log(res)
        if (res.code == 0) {
          this.loading = false
          this.projectPercentList = res.data.data
          this.projectPercentList.forEach((item,i) => {
            item.color = this.colorList[i]
          })
          this.drawPie()
        }
      })
    },
    // 绘制饼图
    drawPie () {
      this.options = {
        tooltip: {
          show: false,
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC"
            }
          }
        },
        legend: [
          // {
          //   data: this.projectPercentList.slice(0,6),
          //   show: true,
          //   type: 'scroll',
          //   icon: 'rect',
          //   itemHeight: 12,
          //   itemWidth: 12,
          //   itemGap: 20,
          //   left: 20,
          //   right: 20,
          //   pageIconColor: '#fff',
          //   pageTextStyle: {
          //     color: "#fff"
          //   },
          //   textStyle: {
          //     color: "#fff"
          //   },
          //   bottom: "8%"
          // },
          {
            data: this.projectPercentList.name,
            show: false,
            type: 'scroll',
            icon: 'rect',
            itemHeight: 12,
            itemWidth: 12,
            itemGap: 20,
            left: 20,
            right: 20,
            pageIconColor: '#fff',
            pageTextStyle: {
              color: "#fff"
            },
            textStyle: {
              color: "#fff"
            },
            bottom: "0%"
          },
        ],
        grid: {
          x: "10%",
          width: "85%",
          y: "4%"
        },
        series: [
          {
            name: "条例分区占比",
            type: "pie",
            radius: ['50%', '70%'],
            center: ['50%', '40%'],
            itemStyle: {
              normal: {
               color: function (colors) {
                  // 随机颜色
                  // return 'rgb('+[
                  //   Math.round(Math.random()*180),
                  //   Math.round(Math.random()*180),
                  //   Math.round(Math.random()*180)
                  // ].join(',')+')'
                  // 指定颜色
                  var colorList = [
                    '#800000',
                    '#B22222',
                    '#FF0000',
                    '#DC143C',
                    '#F76464',
                    '#EF986C',
                    '#E9967A',
                    '#EFC36C',
                    '#FFD700',
                    '#EFEF6C',
                    '#C3EF6C',
                    '#98EF6C',
                    '#5CD65C',
                    '#6CEF98',
                    '#86F9D2',
                    '#6CEFEF',
                    '#5CC9FF',
                    '#ADD8E6',
                    '#3377FF',
                    '#7A7AF0',
                    '#9364F1',
                    '#9a60b4',
                    '#FF00FF',
                    '#9932CC',
                    '#8B008B',
                  ];
                  return colorList[colors.dataIndex];
                }
              },
            },
            emphasis: {
              label: {
                show: true,
                title: '#fff',
                fontSize: '20',
                fontWeight: 'bold',
                color: '#fff',
                formatter: '{b}\n\n{c}'
              }
            },
            data: this.projectPercentList,
            select: {
              disabled: true
            },
            label: {
              normal: {
                show: false,
                formatter: "{b}\n{d}",
                position: 'center'
              }
            }
          },
        ]
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.analyse-item{
    position: relative;
    background: url("../../../assets/img/analyse-item2.png") no-repeat;
    background-size: 100% 100%;
    height: 63vh;
    margin-bottom: 3vh;
    padding-top: 5vh;
    .content{
      width: 100%;
      height: 62vh;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // padding-left: 2vh;
      .center{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pieCircle{
        position: absolute;
        top: 15vh;
        left: 50%;
        margin-left: -17.5vh;
        width: 35vh;
      }
      .legend{
        height: 12vh;
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        position: absolute;
        bottom: 2.5vh;
        overflow-y: scroll;
        div{
          margin: 0.7vh 1.3vh;
          display: flex;
          .box{
            width: 1vh;
            height: 1vh;
          }
          span{
            margin-top: 0.58vh;
          }
        }
      }
    }
}
</style>