<template>
  <div class="left-view">
    <div 
      class="tab-item" 
      :class="tab_active == item.name ? '' : 'tab-item-left'" 
      v-for="(item, index) in tab_list"
      :key="index"
      @click="tabChange(item)"
    >
      <img :src="tab_active == item.name ? item.active : item.img" alt="">
      <div class="title" :class="tab_active == item.name ? '' : 'title-tran'">{{item.title}}</div>
    </div>  
  </div>
</template>

<script>
export default {
  name:'left',
  data() {
    return {
      tab_list:[
        {
          active: require('../../assets/img/search-active.png'),
          img: require('../../assets/img/search.png'),
          name: 'search',
          title: '搜索',
          path: '/search'
        },
        {
          active: require('../../assets/img/analyse-active.png'),
          img: require('../../assets/img/analyse.png'),
          name: 'analyse',
          title: '分析台',
          path: '/analyse'
        }
      ],
      tab_active: '',
    }
  },
  props: {
    // search--搜索，analyse--分析台
    indexName:{
      type: String,
      default: '',
      required: false
    },
  },
  watch: {
    indexName:{
      immediate:true,
      handler(val) {
        this.tab_active = val;
      },
    },
  },
  components: {

  },
  mounted() {

  },
  methods: {
    // tab切换
    tabChange(item){
      this.tab_active = item.name;
      this.$router.push({
        path: item.path
      })
      this.$emit('click')
    },
  }
}
</script>

<style lang="scss" scoped>
.left-view {
  width: 31vh;
  height: 100%;
  // background-color: #05305d;
  display: flex;
  flex-direction: column;
  padding-right: 2vh;
  .tab-item {
    width: 100%;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-bottom: 4.65vh;
    img {
      width: 100%;
    }
    .title {
      font-family: 'YouSheBiaoTiHei';
      font-size: 2.79vh;
      line-height: 2.8vh;
      color: #fff;
      position: absolute;
      left: 16vh;
      top: 50%;
      transform: translate(0, -50%);
    }
    .title-tran {
      transform: translate(0, -85%);
    }
  }
  .tab-item-left {
    padding-left: 4.26vh;
  }
}
</style>
