<template>
  <div class="page-view">
    <Header></Header>
    <div class="main">
      <Left indexName="analyse"></Left>
      <div class="right">
        <div class="res-view">
          <top></top>
          <div class="echart flex-row">
            <div class="analyse-item">
              <left-top></left-top>
              <left-bottom></left-bottom>
            </div>
            <center class="analyse-item"></center>
            <div class="analyse-item">
              <right-top></right-top>
              <!-- <right-bottom></right-bottom> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/header.vue'
import Left from '@/components/Left/left.vue'
import Top from './module/top.vue'
import LeftTop from './module/leftTop.vue'
// import RightBottom from './module/rightBottom.vue'
import Center from './module/center.vue'
import RightTop from './module/rightTop.vue'
import LeftBottom from './module/leftBottom.vue'
export default {
  name:'analyse',
  data() {
    return {
      
    }
  },
  components: {
    Header,
    Left,
    Top,
    LeftTop,
    LeftBottom,
    Center,
    RightTop,
    // RightBottom
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.page-view {
  width: 100%;
  height: 100vh;
  .main {
    width: 100%;
    height: 89.77vh;
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-top: 4.93vh;
    background: linear-gradient(to bottom, #032142, #04305e);
    .right {
      flex: 1;
      height: 83vh;
      // padding-bottom: 5vh;
      .title {
        font-family: 'PangMenZhengDao';
        font-size: 6.67vh;
        color: #fff;
        margin-bottom: 5.59vh;
        line-height: 10vh;
      }
      .analyse-item{
        flex: 1;
      }
    }
    .res-view {
      width: 100%;
      height: 100%;
      border-radius: 1.5vh;
      background-color: rgba(9, 123, 244, .12);
      padding: 2vh 3vh 0;
      position: relative;
      display: flex;
      flex-direction: column;
      .echart{
        padding-top: 3vh;
      }
    }
  }
}
</style>
