<template>
  <div class="analyse-item">
    <title-bg :titleName="titleName" :titleEng='titleEng'></title-bg>
    <div class="center" v-if="loading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 2.25vh" spin />
      </a-spin>
    </div>
    <tag-cloud v-else :data="hotTag" @clickTag="clickTagItem" :config="config"></tag-cloud>
  </div>
</template>

<script>
import TitleBg from './titleBG.vue'
export default {
  components: { TitleBg },
  data () {
    return {
      titleName: '热门标签',
      titleEng: 'POPULAR TAGS',
      hotTag: [], // 热门标签数据列表
      loading: true, // 页面加载
      config: {
        raduis: '10vh',
        hover: false
      }
    }
  },
  created () {
    this.getpopularTags()
  },
  methods: {
    // 词云点击事件
    clickTagItem (tag) {
    },
    // 获取热门标签数据
    getpopularTags () {
      this.$api.analyse.popularTags().then(res => {
        console.log(res)
        if (res.code == 0) {
          this.loading = false
          this.hotTag = res.data.data.map(item => {
            return {
              "id" : item.id,
              "name" : item.name
            }
          })
          console.log(this.hotTag)
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.analyse-item{
    position: relative;
    background: url("../../../assets/img/analyse-item2.png") no-repeat;
    background-size: 100% 100%;
    height: 30vh;
    margin-right: 3vh;
    padding-top: 8vh;
    .center{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tag-cloud{
      width: 100%;
      height: 20vh;
      p{
        font-family: "YouSheBiaoTiHei";
      }
    }
}
</style>