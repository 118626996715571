<template>
  <div class="top-view">
    <div class="top-item flex-row">
      <div class="item item-single">
        <div>事项总量(条)</div>
        <div class="item-num">{{countList.aspect_count}}</div>
      </div>
    </div>
    <div class="top-item flex-row">
      <div class="item item-single">
        <div>案例总数(个)</div>
        <div class="item-num">{{countList.example_count}}</div>
      </div>
    </div>
    <div class="top-item flex-row">
      <div class="item item-single">
        <div>总访问量(次)</div>
        <div class="item-num">{{countList.visit_count}}</div>
      </div>
    </div>
    <div class="top-item flex-row">
      <div class="item item-single">
        <div>总浏览量(次)</div>
        <div class="item-num">{{countList.view_count}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        countList: {} //数量统计列表
    };
  },
  created(){
    this.getCount()
  },
  methods: {
    // 获取数量统计
    getCount() {
      this.$api.analyse.count().then(res => {
        console.log(res)
        if(res.code==0){
            this.countList = res.data
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.top-view {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 0.5vh;
  .top-item {
    width: calc((100% - 24vh) / 4);
    height: 12.5vh;
    background: url("../../../assets/img/analyse-item.png") no-repeat;
    background-size: 100% 100%;
    margin-right: 8vh;
    padding: 2.5vh 2vh;
    color: #fff;
    font-size: 18px;
    // font-size: 1.65vh;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .item-single{
      margin: 0 auto;
      text-align: center;
      .item-num{
        text-align: center;
      }
    }
    .item-1{
      flex: 3;
    }
    .item-2{
      flex: 2;
      white-space: nowrap;
      font-size: 16px;
    }
    .item {
      .item-num {
        font-size: 2.7vh;
      }
      div {
        margin-bottom: 3.8vh;
      }
      .red {
        color: #fe4646;
      }
      .yellow {
        color: #fedf46;
      }
      &:first-child {
        margin-right: auto;
      }
      img{
        width: 0.8vh;
        margin-left: 0.8vh;
        margin-bottom: 0.3vh;
      }
    }
  }
}
</style>