<template>
  <div class="analyse-item">
    <title-bg :titleName="titleName" :titleEng='titleEng'></title-bg>
    <div class="content">
      <div class="btn">
        <div class="btn-item" @click="btn_active = 1" :class="btn_active == 1 ? 'btn_active' : ''">热门事项</div>
      </div>
      <div class="list">
        <tr>
          <!-- <td class="list-id">序号</td> -->
          <td class="list-code">编号</td>
          <td class="list-name">事项名称</td>
          <td class="list-count">浏览量</td>
        </tr>
        <div class="center" v-if="loading">
          <a-spin>
            <a-icon
              slot="indicator"
              type="loading"
              style="font-size: 2.25vh"
              spin
            />
          </a-spin>
        </div>
        <div class="list-box" ref="WorkListBodyBorder" v-else>
          <div class="list-item-box" ref="WorkListItem">
            <tr class="list-item" v-for="(item,i) in aspectList" :key="i" @click="chooseItem = i " :class="chooseItem == i ? 'choose' : ''">
              <!-- <td class="list-id">{{item.id}}</td> -->
              <td class="list-code">{{item.code}}</td>
              <td class="list-name" style="width: 0;">{{item.name}}</td>
              <td class="list-count">{{item.view_count}}</td>
            </tr>
          </div>
          <div class="WorkListItem" ref="WorkListItem2" >
            <tr class="list-item" v-for="(item,i) in aspectList" :key="i" @click="chooseItem = i " :class="chooseItem == i ? 'choose' : ''">
              <td class="list-code">{{item.code}}</td>
              <td class="list-name" style="width: 0;">{{item.name}}</td>
              <td class="list-count">{{item.view_count}}</td>
            </tr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBg from './titleBG.vue'
export default {
  components: { TitleBg },
  data () {
    return {
      titleName: '排行榜',
      titleEng: 'RANKING LIST',
      btn_active: 1, // 选中按钮
      chooseItem: -1, // 选中的列表项id
      aspectList: [], // 热门事项列表
      loading: true, // 页面加载
      page: 1, // 热门事项列表页数
      totalPage: 0, // 热门事项列表总页数
    }
  },
  created () {
    
  },
  mounted () {
    this.getHotAspect()
  },
  methods: {
    // 获取热门事项数据
    getHotAspect () {
      let params = {
        page: this.page
      }
      this.$api.analyse.hotAspect(params).then(res => {
        console.log(res)
        if (res.code == 0) {
            this.loading = false
            this.totalPage = res.data.last_page
            this.aspectList = res.data.data
            this.autoRoll()
        }
      })
    },
    // 滚动效果
    autoRoll(){
      var time = setInterval(() => {
        this.$nextTick(() => {
          // console.log(this.$refs.WorkListBodyBorder.scrollTop)
          if(this.$refs.WorkListBodyBorder.scrollTop >= this.$refs.WorkListItem.scrollHeight + 12) {
            this.$refs.WorkListBodyBorder.scrollTop = 0;                 
          } else {
            this.$refs.WorkListBodyBorder.scrollTop ++;
          }
        })
      }, 40);
      this.$nextTick(() => {
        this.$refs.WorkListBodyBorder.onmouseover = () => {
            clearInterval(time);
        }
        this.$refs.WorkListBodyBorder.onmouseout = () => {
          time = setInterval(() => {
            this.$nextTick(() => {
              if(this.$refs.WorkListBodyBorder.scrollTop >= this.$refs.WorkListItem.scrollHeight + 12) {
                this.$refs.WorkListBodyBorder.scrollTop = 0;                          
              } else {
                this.$refs.WorkListBodyBorder.scrollTop ++;
              }
            })
          }, 40);
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.analyse-item{
    position: relative;
    background: url("../../../assets/img/analyse-item2.png") no-repeat;
    background-size: 100% 100%;
    height: 63vh;
    margin-right: 3vh;
    padding-top: 5vh;
    .content{
      width: 100%;
      height: 60vh;
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      padding: 1.5vh 2vh;
      .btn{
        display: inline-flex;
        width: 60%;
        height: 3.5vh;
        color: #FFFFFF;
        font-size: 1.66vh;
        cursor: pointer;
        .btn-item{
          // width: 8vh;
          text-align: center;
          line-height: 3.5vh;
          padding: 0 1.5vh;
        }
        .btn_active{
          background: linear-gradient(180deg, rgba(97, 173, 255, 0) 0%, rgba(8, 185, 244, 0.64) 97.22%);
          border: 2px solid #33CCFF;
          border-radius: 8px;
        }
      }
      .list{
        color: #fff;
        font-size: 1.66vh;
        line-height: 2vh;
        margin-top: 2vh;
        .center{
          width: 100%;
          height: 45vh;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        tr{
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.5vh;
          .list-code{
            flex: 2;
          }
          .list-count{
            flex: 1.2;
            text-align: center;
          }
          td{
            flex: 3;
            text-align: center;
          }
          .list-name{
            text-align: center;
          }
        }
        .list-box{
          overflow: hidden;
          height: 45vh;
        }
        .list-item:hover{
          background: url('../../../assets/img/rank-item.png');
          background-size: 100% 100%;
        }
        .list-item{
          display: flex;
          background: rgba(8, 112, 220, 0.25);
          justify-content: space-between;
          margin: 1.5vh 0;
          cursor: pointer;
          .list-id{
            flex: 1;
          }
          .list-code{
            flex: 2;
          }
          .list-count{
            flex: 1;
          }
          td{
            flex: 3;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 1.5vh;
          }
        }
        .choose{
          background: url('../../../assets/img/rank-item.png');
          background-size: 100% 100%;
        }
      }
    }
    
}
</style>